import React, {useEffect, useRef, useState} from 'react' 
import SimpleReactLightbox, { SRLWrapper, useLightbox }from 'simple-react-lightbox'
import {getPhotos,getDescription,getQuality,getCover } from '../../helpers/helper.rendering'
import { graphql, useStaticQuery } from 'gatsby';
import {getDevelopmentsAction, getDevelopmentsFilterAction, updateFiltersDevelopment} from '../../redux/developmentsDucks'
import { getReverseOrder,getFeatured, getName, getFakeAddress, getLocation,getLastFeatured } from '../../helpers/helper.developments';
import OwlCarousel from 'react-owl-carousel3'
import { connect } from 'react-redux'
import { Link } from '@reach/router';
import Loading from '../Loading'
//Helpers
// import { getLocation, getLastFeatured } from '../../helpers/helper.developments';
// import { getCover } from '../../helpers/helper.rendering';
// import { graphql, useStaticQuery } from 'gatsby';
import { getActionByPage, getActionByType } from '../../helpers/helper.actions';
import { getTotalListFilters } from '../../redux/propertiesDucks';




export function Main({api_key,developmentsFilters,dispatch,developments,actions,loading}) {
    const [filter,setFilter] = useState(0)

    const { realEstate } = useStaticQuery(graphql`
    query {
        realEstate {
            slogan
            name
        }
    }`)
    //media-push
    const slider_action = getActionByPage(getActionByType(actions,'Slider de Imagenes Encabezado'),'Inicio')
    const image_action = getActionByPage(getActionByType(actions,'Imagen Encabezado'),'Inicio')
    const video_action = getActionByPage(getActionByType(actions,'Video Encabezado'),'Inicio')

    useEffect(() => {
        if(api_key){
            dispatch(getDevelopmentsAction()) // Get all developments
            dispatch(getTotalListFilters()) // Get all developments
        }
    },[api_key])

    const makeLink = (development) => {
        if(development){
            return "/emprendimientos/" + development.id 
        }
    }

    const getSize = () => (screen.width < 992) ? false : true
    
    const goTo = () => {
        if (video_action[0].url_destiny) {
            window.open(video_action[0].url_destiny, '_blank', 'noopener,noreferrer')
        }
    }

    //fin media-push

    const slider = useRef()
    const options = {
        settings: {
            overlayColor: "rgba(0, 0, 0, 0.9)",
            autoplaySpeed: 1500,
            transitionSpeed: 900,
            disableWheelControls:true
        },
        buttons: {
            backgroundColor: "rgba(0, 0, 0, 0.9)",
            iconColor: "rgba(255,255,255, 1)",
        },
        caption: {
            captionColor: "#000",
            captionFontFamily: "Raleway, sans-serif",
            captionFontWeight: "300",
            captionTextTransform: "uppercase",
        }
        };
        useEffect(() => {
            // console.log(api_key)
            if(api_key){	
                dispatch(updateFiltersDevelopment(filter))
                dispatch(getDevelopmentsFilterAction()) // Get all developments
            }
        },[filter,api_key])

       
    return developments && (
        <></>
        // <div id="section-galery">
        //     <div class="" id="dev-secuencial">
        //         {developments?.data?.objects &&
        //             <div class="slider" id="dev-gallery">
        //             <OwlCarousel
        //                 items={1}
        //                 ref={slider}
        //                 margin={0}
        //                 nav={false}
        //                 dots={true}
        //                 loop={true}
        //                 rewind={true}
        //                 id="prop-gallery">
        //                     {getReverseOrder(getFeatured(developments?.data?.objects)).map((development, index)=>(
        //                         <div class="item banner-main banner-ficha emprendimiento">
        //                             <img src={getCover(getPhotos(development)).original} className='object-cover object-center cursor-pointer' alt={realEstate?.name} />
        //                             <div class="info-banner position-absolute">
        //                                 <div class="info row align-items-center text-center justify-content-center">
        //                                     <div class="col-8 col-lg-12">
        //                                         <h2>DESCUBRÍ <br /></h2>
        //                                         <h1>{getName(development)}</h1> 
        //                                         <a target={'_blank'} href={"/emprendimientos/" + development.id + (development.unities ? '?unidades=' + makeUrlUnities(development.unities) : '')} class="btn btn-fifth mt-3 mt-lg-5"><span class="d-block d-lg-inline">CONOCÉ MÁS</span></a>
                                                    
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     ))}    
        //             </OwlCarousel>
        //             <div className="arrows-owl">
        //                 <div className="arrow" onClick={() => slider.current.prev()}> <div className='next'><i className='icon-arrow-right'> </i></div></div>
        //                 <div className="arrow " onClick={() => slider.current.next()}> <div className='next'><i className='icon-arrow-right'> </i></div> </div>
        //             </div>
        //         </div>}
        //     </div>
        // </div>
    )
    // return !loading ? (
    //     slider_action?.length > 0 || video_action?.length > 0 //Caso Actions video/slider
    //     ?   <>
    //             {
    //                 slider_action?.length > 0
    //                 &&  <>
    //                         <div id="section-galery-home">
    //                             <div id="dev-secuencial">
    //                                 <div className="slider" id="dev-gallery">
    //                                     <OwlCarousel
    //                                         items={1}
    //                                         ref={slider}
    //                                         margin={0}
    //                                         nav={false}
    //                                         dots={true}
    //                                         loop={true}
    //                                         rewind={true}
    //                                         autoplay={true}
    //                                         autoplayTimeout={parseInt(slider_action[0]?.transition_time)*1000}
    //                                         id="prop-gallery">
    //                                             { slider_action[0].files.map((file, index)=>(
    //                                                 <div className="item banner-main banner-ficha emprendimiento" key={index}>
    //                                                     <img src={file.file} className="object-cover object-center cursor-pointer" alt={realEstate?.name} />
    //                                                     <div className="info-banner position-absolute">
    //                                                         <div className="info row align-items-center text-center justify-content-center">
    //                                                             <div className="col-8 col-lg-12">
    //                                                                 <a target={'_blank'} href={file.url_destiny} className={"link-gallery " + (!file.url_destiny && 'd-none')}></a>
    //                                                             </div>
    //                                                         </div>
    //                                                     </div>
    //                                                 </div>
    //                                             )) }
    //                                     </OwlCarousel>
    //                                     <div className="arrows-owl">
    //                                         <div className="arrow" onClick={() => slider.current.prev()}>
    //                                             <div className="next"><i className="icon-arrow"> </i></div>
    //                                         </div>
    //                                         <div className="arrow " onClick={() => slider.current.next()}>
    //                                             <div className="next"><i className="icon-arrow"> </i></div>
    //                                         </div>
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                             {/* <Searcher /> */}
    //                         </div>
    //                     </>
    //             }
    //             {
    //                 video_action.length > 0
    //                 &&  <>
    //                         <div className={"banner-main " + (video_action[0].url_destiny === '' ? 'cursor-default' : 'cursor-pointer')} onClick={() => goTo() }>
    //                             { getSize() &&
    //                                 <video loop autoPlay muted >
    //                                     <source src={video_action[0].file} type="video/mp4" />
    //                                 </video> }
    //                                 {/* <Searcher /> */}
    //                         </div>
    //                     </>
    //             }
    //         </>
    //     // Casos sin actiones (Con emprendimientos o sin).
    //     :  
    //     <>
    //         {
    //             developments?.data?.objects?.length && !image_action && getLastFeatured(developments.data.objects) ? (
    //             <div id="home-main" class="banner-main" style={{backgroundImage:'url(' + (developments.data ? getCover(getLastFeatured(developments.data.objects).photos)?.original : realEstate?.main_image) + ")"}} >
    //                 <div class="item d-flex justify-content-center text-center align-items-center">
    //                     <div class="opacity"></div>
    //                     <div class="container" style={{zIndex:"1"}}>
    //                         <div class="row align-items-center text-center justify-content-center">
    //                             <div class="col-12 pb-5">
    //                                 <h1>{developments.data && getLastFeatured(developments.data.objects).name}</h1>
    //                                 <p>
    //                                     {developments.data && getLocation(getLastFeatured(developments.data.objects),true)[0]}
    //                                     <br />
    //                                     {developments.data && getLocation(getLastFeatured(developments.data.objects),true)[1]}
    //                                 </p>
    //                                 <a href={developments.data && makeLink(getLastFeatured(developments.data.objects))} target="_blank" class="btn btn-blue mb-5">DESCUBRILO</a>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //                 {/* <Searcher /> */}
    //             </div>
    //             ):
    //             <div id="home-main" onClick={() => image_action[0].url_destiny ? window.open(image_action[0].url_destiny, '_blank', 'noopener,noreferrer') : ''} class={"banner-main " + (image_action.length === 0 || image_action[0]?.url_destiny === '' ? 'cursor-default' : '') } style={{backgroundImage:'url(' + (image_action.length > 0 ? image_action[0]?.file : realEstate?.main_image) + ")"}} >
    //                 {image_action.length === 0 && <div class="height-100 item d-flex justify-content-center text-center align-items-center">
    //                     <div class="opacity"></div>
    //                     <div class="container" style={{zIndex:"1"}}>
    //                         <div class="row align-items-center text-center justify-content-center">
    //                             <div class="col-12 pb-5">
    //                                 <h1>
    //                                     {realEstate?.slogan}
    //                                 </h1>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>}
    //                 {/* <Searcher /> */}
    //             </div>
    //         }
    //     </>
    // ):
    // <Loading absolute={true} />
}
export default connect(state => ({
    developmentsFilters: state.developments.developmentsFilters,
    actions:state.actions.actions,
    loading: state.developments.loading,
    api_key: state.settings.keys.tokko,
    developments: state.developments.developments,
}),null)(Main);